.circle-picker {
  justify-content: center;
}
.circle-picker > span > div > span > div {
  border-radius: 25% !important;
}
.Toastify__toast-body {
  font-size: 13px !important;
}
.height600 {
  height: 600px;
}
.rbc-event-content, .rbc-event-label {
  font-size: 12px!important;
  font-weight: 600;
}

.rbc-event-label::after {
  content: "\a";
  white-space: pre;
}

.cke_editable_inline {
  border: 1px solid #dadada;
  height: 200px;
  overflow: auto;
}

textarea {
  font-family: inherit;
}

.dateTimePicker {
  background-color: "#000";
}

.iframe-placeholder {
   background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%23FF0000" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="24" text-anchor="middle">Loading...</text></svg>') 0px 0px no-repeat;
}

.react-multiple-carousel__arrow {
  top: 0!important;
}

.react-multiple-carousel__arrow--left {
  right: calc(4% + 50px)!important;
  left: auto!important;
}

.carousel-container {
  padding-top: 50px;
}
.carousel-item-margin-20-px {
  margin-right: 2px;
}
.fc{
  font-size: 14px!important;
}
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),linear-gradient(195deg, #000, #000)!important;
  border-color: #000!important;
}

.fc-daygrid-event {
  background-color: #1B1C29!important;
  border: 1px solid #1B1C29!important;
}